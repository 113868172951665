.stepper {
  position: fixed;
  top: 48px;
  width: 33.33%;
  z-index: 3000;
  background-color: rgb(255, 255, 255);
  display: flex;
  height: 3px;
}

.step {
  background-color: rgb(219, 219, 219);
  flex: 1 1 0%;
  margin: 0px 2px;
  transition: background-color 250ms ease 0s;
}

.error-section {
  position: fixed;
  z-index: 3000;
  top: 47px;
  padding: 8px;
  background-color: rgb(196, 33, 33);
  width: 33.33%;
  font-size: 13px;
}

.unit {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219) !important;
  border-image: initial;
  border-radius: 4px !important;
  width: 33.33%;
  &:hover {
    border-width: 2px;
  }
}
.unit-icon {
  font-size: 26px;
  color: rgb(74, 74, 74);
}
.active-method {
  border-width: 2px !important;
  outline: none;
}

.payment-icon {
  width: 22px;
}

.apply-btn {
  margin-left: 16px;
}

.rtl {
  .apply-btn {
    margin-right: 16px;
    margin-left: unset !important;
  }
}

.ltr-1ieammf {
  font-size: 22px !important;
  font-weight: 400 !important;
  line-height: 1.27;
  letter-spacing: 0.3px;
  padding-bottom: 8px;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}

.ltr-qtvxxo {
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 1.33;
  letter-spacing: -0.2px;
  padding-bottom: 16px;
  color: rgb(97, 97, 97);
  overflow-wrap: break-word;
  word-break: break-word;
}

.master-input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: #0000001f;
  border-width: 1px;
  &::placeholder {
    color: #00000030;
    font-size: 16px !important; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #00000030;
    font-size: 16px !important;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #00000030;
    font-size: 16px !important;
  }
}

.master-input:focus {
  &::-webkit-input-placeholder {
    color: transparent;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: transparent;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }

  &:-ms-input-placeholder {
    color: transparent;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
}

.master-logos {
  width: 30px;
}

.vertical-line {
  border-left: 1px solid #0000001f;
}

.credit-submit {
  margin-top: 24px;
  width: 100%;
  border-radius: 4px;
  color: #000;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  font-size: 13px;
  -webkit-letter-spacing: -0.1px;
  -moz-letter-spacing: -0.1px;
  -ms-letter-spacing: -0.1px;
  letter-spacing: -0.1px;
  line-height: 1.23;
  padding: 8px 16px;
  min-height: 32px;
  margin-top: 24px;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    outline: none;
    border-color: #4a4a4a;
  }
}
.content {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.delivery-icons {
  font-size: 20px;
  color: #4a4a4a;
  line-height: 1.4;
}
.ltr-p9r53k {
  cursor: pointer;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-size: cover;
  position: relative;
  height: 114px;
  display: flex;
  text-decoration: none;
  background-repeat: no-repeat;
}
.ltr-14d91vi {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  font-size: 13px;
  letter-spacing: -0.1px;
  line-height: 1.23;
  min-height: 32px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  touch-action: manipulation;
  user-select: none;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219);
  border-image: initial;
  padding: 8px 16px;
  margin: 16px;
  text-decoration: none;
  transition: all 0.2s ease-in 0s;
}
.ltr-14d91vi:hover:enabled,
.ltr-14d91vi:focus {
  outline: none;
  border-color: rgb(74, 74, 74);
}
.ltr-ndfmgy {
  position: relative;
  cursor: pointer;
  flex: 1 1 0%;
}
.ltr-13atv4k {
  padding-top: 8px;
  padding-bottom: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  gap: 8px;
}
.ltr-1scfs3q,
.active-method {
  color: rgb(0, 0, 0);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  border-style: solid;
  border-color: rgb(219, 219, 219);
  border-image: initial;
  border-radius: 4px;
  p {
    font-size: 13px;
  }
  &:hover {
    outline: none;
    border-width: 2px;
    padding: 8px 0px;
  }
}
.ltr-1scfs3q {
  padding: calc(9px) 0px;
  border-width: 1px;
}
.active-method {
  padding: 8px 0px;
  border-width: 2px;
}
.ltr-v9l4h9 {
  color: rgb(153, 153, 153);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
}
.title {
  font-size: 22px;
  line-height: 1.27;
  letter-spacing: 0.3px;
  font-weight: 600;
  padding-bottom: 8px;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}

.ltr-13w7zlb {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  padding-right: 8px;
  color: rgb(97, 97, 97);
  overflow-wrap: break-word;
  word-break: break-word;
}
.area-section {
  cursor: pointer;
  &:hover {
    color: rgb(191, 4, 20);
  }
}
.area-icon {
  font-size: 27px;
}

.checkout-arrow {
  font-size: 11px;
  color: #868686;
}
.ltr-1y4p49f {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.23;
  letter-spacing: -0.1px;
  padding-right: 8px;
  color: rgb(97, 97, 97);
  overflow-wrap: break-word;
  word-break: break-word;
}

.ltr-1o15x0g {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.23;
  letter-spacing: -0.1px;
  flex-shrink: 0;
  color: rgb(97, 97, 97);
  overflow-wrap: break-word;
  word-break: break-word;
}

.req-section,
.confirm-more {
  padding-left: 31px;
}

.rtl {
  .req-section,
  .confirm-more {
    padding-right: 31px;
    padding-left: unset;
  }
}

.confirm-payment-icon {
  width: 19px;
  height: 11px;
}
.checkout-layout-container {
  margin-top: 48px;
}

.ltr-ttj5pb {
  line-height: 0;
  margin-left: 8px;
  font-size: 24px;
  height: 24px;
  -webkit-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.ltr-14g83yz {
  line-height: 0;
  font-size: 24px;
  height: 24px;
  color: currentcolor;
}

.ltr-11xvhlv {
  border-bottom: 1px solid;
  border-color: #f5f5f5;
  margin-top: 8px;
}

.ltr-ptzb68 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  -webkit-letter-spacing: -0.2px;
  -moz-letter-spacing: -0.2px;
  -ms-letter-spacing: -0.2px;
  letter-spacing: -0.2px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: #616161;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

@media (max-width: 991.98px) {
  .error-section {
    width: 100% !important;
  }
  .extra-status {
    display: block;
  }
  .delivery-tabs {
    width: 100% !important;
  }
}
