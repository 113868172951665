.ltr-1qhf5kz {
  margin-left: -16px;
  margin-right: -16px;
  margin-top: 24px;
  position: sticky;
  top: 48px;
  z-index: 1000;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  border-width: 2px 2px;
  border-style: solid none;
  border-color: rgb(245, 245, 245) rgb(245, 245, 245);
  border-image: initial;
  border-left: none;
  border-right: none;
}

.ltr-1j8qet7 {
  display: flex;
}

.ltr-1ryzbh9 {
  display: inline-flex;
}

.ltr-dfpqc0 {
  position: relative;
  cursor: pointer;
}
.ltr-1yo3nmz {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 68px;
  width: 108px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
}
.ltr-l93xhc {
  position: absolute;
  opacity: 0;
  height: 0px;
  width: 0px;
}
.ltr-1rn4h86 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: rgb(97, 97, 97);
  overflow-wrap: break-word;
  word-break: break-word;
}

.ltr-1vueemx {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  letter-spacing: -0.4px;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  text-transform: capitalize;
  word-break: break-word;
}

.ltr-bozcr4 {
  margin-top: 16px;
  padding-top: 8px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
}
