.branches-section {
  .branches-list-group {
    border: 0 !important;
  }
}
.list-group {
  padding: 0;
}

.branches-list-section {
  margin-top: 48px;
}

.lang-switch {
  font-size: 19px !important;
  font-weight: 500;
  &:hover {
    color: #fff !important;
    opacity: 0.8 !important;
  }
}

.custom-text-align {
  text-align: right;
}

.ltr-wp05q2 {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  font-size: 13px;
  letter-spacing: -0.1px;
  line-height: 1.23;
  min-height: 32px;
  margin-left: 8px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  touch-action: manipulation;
  user-select: none;
  flex: 1 1 0%;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219);
  border-image: initial;
  padding: 8px 16px;
  text-decoration: none;
  transition: all 0.2s ease-in 0s;
}
.bullet {
  font-size: 42px;
  font-weight: 500;
  line-height: 0.44;
  letter-spacing: -0.2px;
  overflow-wrap: break-word;
  word-break: break-word;
}

.rtl {
  .custom-text-align {
    text-align: left;
  }
}

.ltr-1v9c9b3 {
  margin-left: 16px;
  margin-right: 16px;
  background-color: rgb(255, 255, 255);
  padding: 16px 0px;
  list-style: none;
}

.ltr-1gvv4e3 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}

.ltr-1kefxcu {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.23;
  letter-spacing: -0.1px;
  padding-top: 4px;
  color: rgb(143, 143, 148);
  overflow-wrap: break-word;
  word-break: break-word;
}
.ltr-1d816xa:hover {
  opacity: 0.7;
}

.ltr-tse85g {
  flex-direction: column;
  margin-top: 8px;
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 16px;
}

.ltr-v40abw {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: rgb(196, 33, 33);
  overflow-wrap: break-word;
  word-break: break-word;
}
.ltr-k97gjc {
  font-size: 13px;
  line-height: 1.23;
  letter-spacing: -0.1px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}

.header-gradient-bg {
  background-image: linear-gradient(#0009, #0000);
  .back {
    path {
      fill: '#fff' !important;
    }
  }
}
