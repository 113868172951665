.ltr-1079k8q {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.ltr-1vttx28 {
  width: 100%;
  min-height: 256px;
  height: 100%;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  object-fit: fill;
}

.ltr-1i60a19 {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  -webkit-letter-spacing: -0.4px;
  -moz-letter-spacing: -0.4px;
  -ms-letter-spacing: -0.4px;
  letter-spacing: -0.4px;
  color: #000;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.ltr-18j2v0h {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  -webkit-letter-spacing: -0.2px;
  -moz-letter-spacing: -0.2px;
  -ms-letter-spacing: -0.2px;
  letter-spacing: -0.2px;
  margin-top: 4px;
  color: #616161;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  white-space: pre-wrap;
}

.ltr-1rn4h86 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  -webkit-letter-spacing: -0.2px;
  -moz-letter-spacing: -0.2px;
  -ms-letter-spacing: -0.2px;
  letter-spacing: -0.2px;
  color: #616161;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.ltr-asx5e1 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: rgb(0, 0, 0);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease 0s;
}
.ltr-1mx4hym {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.23;
  letter-spacing: -0.1px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: rgb(255, 255, 255);
  background-color: rgb(153, 153, 153);
  border-radius: 12px;
  transition: all 0.2s ease 0s;
  height: 24.2px;
}

.ltr-asx5e1 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: rgb(0, 0, 0);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease 0s;
}

.ltr-17jqah9 {
  color: rgb(97, 97, 97);
  flex-shrink: 0;
  margin-left: auto;
}
.rtl {
  .ltr-17jqah9 {
    color: rgb(97, 97, 97);
    flex-shrink: 0;
    margin-right: auto;
    margin-left: unset;
  }
}
