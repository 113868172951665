$field-container-padding: 5px;
$label-padding: $field-container-padding !default;

.field-container {
  position: relative;
  border-bottom: 1px solid rgb(245, 245, 245);
  height: 35px;
  width: 100%;
}

.field-placeholder {
  position: absolute;
  top: 50%;
  transform: translate(#{$label-padding}, -50%);
  pointer-events: none;
  transition: all 0.14s ease-in-out;
  font-size: 15px;
  color: #adadad;
}

input[type='text'].field-input {
  color: #000;
  border: none;
  padding: $label-padding;
  font-size: 15px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  bottom: 0px;
  &:focus {
    outline: none;
  }
  &.c-fix,
  &:focus,
  &:not(:placeholder-shown) {
    border-color: transparent;
    ~ label {
      color: #adadad;
      font-size: 12px;
      left: -7px;
      //Come up with a calculation for this
      top: calc(30% - 1.4rem);
      transform: translate(#{$label-padding}, 0%);
    }
  }

  &::-webkit-input-placeholder {
    color: transparent;
  }
  &::-moz-placeholder {
    color: transparent;
  }
  &:-ms-input-placeholder {
    color: transparent;
  }
}

.PhoneInputInput {
  border-top: 0;
  border-bottom: 1px solid rgb(245, 245, 245);
  border-left: 0;
  border-right: 0;
}
.label {
  color: rgb(153, 153, 153);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
}

.error {
  color: #c42121;
  .PhoneInputInput {
    border-bottom: 1px solid #c42121;
  }
}
.input-feedback {
  color: #c42121;
}
