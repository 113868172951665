.main-section {
  position: absolute !important;
  left: 0;
}
.burger_hamburger__21cuQ {
  margin: 0 !important;
}
.navbar-toggler {
  border: 0 !important;
}
.rtl {
  direction: rtl !important;
  text-align: right;
  .main-section {
    left: unset;
    right: 0;
  }
  .intro-section {
    right: unset;
    left: 0;
  }
}

.lang-switch {
  svg {
    fill: #fff;
  }
}

.intro-section {
  top: 0;
  display: none;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;

  .logo-container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .logo {
    width: 150px;
    height: 150px;
    margin-bottom: 16px;
  }
  .title {
    color: #fff;
    font-size: 22px;
  }
  .sub-title {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.33;
  }
}

.inner-layout {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
}

.main-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: inherit;
  height: inherit;
}

.menu-container {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 2000;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.logo-title-section {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  z-index: 1000;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.logo-title-container {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.langSvg {
  fill: #fff;
  opacity: 0.8;
}

.bagSvg {
  display: none;
}

.searchSvg {
  -webkit-animation-name: swashIn;
  animation-name: swashIn;
}

@-webkit-keyframes swashIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
  }
  90% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(0.9, 0.9);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
  }
}

@keyframes swashIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  90% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(0.9, 0.9);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
.small-logo {
  opacity: 0;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 50%;
}

.ltr-8xnx94 {
  display: none;
}

@media (max-width: 991.98px) {
  .stepper {
    width: 100% !important;
  }
  .res-header {
    display: none;
  }
  .res-main-section {
    top: 0 !important;
  }
  .public-header {
    width: 100%;
  }
  .menu-items {
    color: #000 !important;
    font-weight: 400 !important;
    opacity: 1 !important;
  }
  .gradient-bg {
    background-image: linear-gradient(#0009, #0000);
    .langSvg {
      opacity: 1;
    }
    .burger_hamburger-inner__14XT2 {
      background-color: #fff;
      &::before,
      &::after {
        background-color: #fff;
      }
    }
    .searchSvg {
      display: none;
    }
  }
  .customNav {
    .burger_hamburger-inner__14XT2 {
      background-color: #000;
      &::before,
      &::after {
        background-color: #000;
      }
    }
  }

  .mini-logo {
    opacity: 1;
  }

  .light {
    background-color: #fff;
  }

  .bagSvg {
    display: initial;
    fill: #fff;
  }

  .scrolledSvg {
    fill: #000 !important;
    opacity: 1;
  }
  .menu-container {
    position: fixed;
    top: 0px;
    z-index: 4000;
  }
  .intro-section {
    position: relative !important;
  }
  .inner-layout {
    position: relative !important;
  }
  .logo-title-section {
    background-color: #fff;
    top: 256px;
    display: flex;
    height: 132px;
    padding: 16px;
    justify-content: unset;
  }
  .main-background {
    height: 256px;
  }
  .main-section {
    top: 368px;
  }
  .logo-title-container {
    flex-direction: row;
    .title {
      color: #000 !important;
      font-size: 17px;
    }
  }
  .logo {
    width: 80px !important;
    height: 80px !important;
    margin-right: 16px;
    margin-bottom: 0 !important;
  }

  .rtl {
    .logo {
      margin-left: 16px;
      margin-right: unset;
    }
  }
  .ltr-1usrurx {
    width: 100% !important;
  }
  .ltr-8xnx94 {
    position: absolute;
    top: 17px;
    right: 8px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 10px;
    padding: 2px;
    line-height: 10px;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    pointer-events: none;
    background: #d80417;
    display: inline;
  }
  .ltr-1ifilxi {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.23;
    -webkit-letter-spacing: -0.1px;
    -moz-letter-spacing: -0.1px;
    -ms-letter-spacing: -0.1px;
    letter-spacing: -0.1px;
    color: #fff;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

.navbar-toggler {
  padding: 0 !important;
}
.burger_hamburger-box__3lYk7 {
  width: 30px !important;
  transform: scale(0.7);
}

.collapse:not(.show) {
  display: none;
  transition: 0.35s all ease;
}
.collapse.show {
  height: 100vh;
  transition: 0.35s all ease;
  .navbar-nav {
    border-top: 1px solid #eee;
  }
}

.collapsing {
  height: 0 !important;
  transition: all 0.35s ease !important;
}

.ltr-oz6p4a {
  width: 164px;
  color: rgb(255, 255, 255);
  background-color: rgb(216, 4, 23);
  font-size: 15px;
  letter-spacing: -0.2px;
  line-height: 1.27;
  min-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  touch-action: manipulation;
  user-select: none;
  border-radius: 4px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 14px 16px;
  text-decoration: none;
  transition: all 0.2s ease-in 0s;
}
