.height-100 {
  height: calc(100vh - 100px);
}

*:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent !important;
}

a {
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
    color: unset;
  }
}
.text-uppercase {
  text-transform: uppercase;
}

// h1 {
//   font-size: $font-size-16 !important;
// }
// h2 {
//   font-size: $font-size-17 !important;
//   font-weight: $font-weight-600;
// }
// h3 {
//   font-size: $font-size-15 !important;
//   color: $gray_1;
//   font-weight: $font-weight-400 !important;
// }
// p {
//   font-size: $font-size-13 !important;
//   line-height: 1.23;
//   letter-spacing: -0.1px;
//   color: rgb(0, 0, 0);
//   overflow-wrap: break-word;
//   word-break: break-word;
// }
hr {
  border-top: 8px solid $main-bg !important;
  margin: 0 !important;
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.main-bg {
  background-color: $main-bg;
}
.white-bg {
  background-color: #ffffff;
}

.donut {
  width: 2rem;
  height: 2rem;
  margin: 2rem;
  border-radius: 50%;
  border: 0.2rem solid rgba($bluebell, 0.3);
  border-top-color: $bluebell;
  animation: 1.5s spin infinite linear;

  &.multi {
    border-bottom-color: $bluebell;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.invalid {
  background-color: rgb(196, 33, 33) !important;
}

.rtl {
  .back-arrow-holder {
    transform: rotate(180deg);
  }
}

.gap {
  height: 50px;
}

.page-header {
  height: 48px;
}

.header-title {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  letter-spacing: -0.4px;
  text-align: center;
  padding-left: 9%;
  padding-right: 9%;
  -webkit-box-align: center;
  align-items: center;
  color: inherit;
  position: absolute;
  left: 0px;
  right: 0px;
  overflow-wrap: break-word;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  opacity: 1;
  transform: scale3d(1, 1, 1);
  flex: 1 1 auto;
  overflow: hidden;
  transition: opacity 0.5s ease 0s, transform 0.2s ease 0s;
}

.link-header {
  font-size: 32px;
  height: 40px;
  color: currentColor;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
  background-color: #0000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-stretch: normal;
  font-style: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 0;
  touch-action: manipulation;
  line-height: 1;
  -webkit-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
.public-header {
  position: fixed;
  z-index: 3000;
  top: 0;
  padding: 8px;
  width: 33.33%;
  background-image: linear-gradient(#fff, #fff);
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 8px;
  top: 0;
  position: -webkit-sticky;
  z-index: 3000;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #000;
}

.ltr-17sbyd3 {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 auto;
}

.display-none {
  display: none;
}
