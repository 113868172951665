.order-status-container {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 46px;
}
.section {
  flex-direction: column;
  display: flex;

  background-color: rgb(255, 255, 255);
  padding: 16px;
}

.ltr-1rn4h86 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: rgb(97, 97, 97);
  overflow-wrap: break-word;
  word-break: break-word;
}
