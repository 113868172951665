/// custom radio btn
.ltr-g2zmlc {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: rgb(0, 0, 0);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  transition: all 0.2s ease 0s;
}
.ltr-l93xhc {
  position: absolute;
  opacity: 0;
  height: 0px;
  width: 0px;
}
.ltr-1rpgelp {
  position: relative;
  display: inline-block;
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-width: 2px;
  border-style: solid;
  border-image: initial;
  border-color: rgb(219, 219, 219);
  border-radius: 50%;
  background: rgb(255, 255, 255);
  transition: all 0.2s ease 0s;
}
.eppvlgj0:checked + .ltr-1rpgelp::after,
.eppvlgj0:not(:checked) + .ltr-1rpgelp::after {
  content: '';
  position: absolute;
  opacity: 1;
  width: 8px;
  height: 8px;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  background: rgb(255, 255, 255);
  border-radius: 50%;
  transition: all 0.2s ease 0s;
}
.ltr-17sbyd3 {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 auto;
}
.eppvlgj0:not(:checked) + .ltr-1rpgelp::after {
  opacity: 0;
  transform: scale(0);
}

.badge-require {
  background-color: rgb(153, 153, 153);
  color: #ffffff;
}

/// custom checkbox
.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 24px;
    height: 24px;
    background: white;
    border-width: 2px;
    border-style: solid;
    border-image: initial;
    border-color: rgb(219, 219, 219);
    border-radius: 4px;
  }
  &:focus + label:before {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.12);
  }
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 10px;
    background: #fff;
    width: 2px;
    height: 3px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.rtl {
  .ltr-1rpgelp {
    margin-right: unset;
    margin-left: 8px;
  }
  .styled-checkbox + label:before {
    margin-right: unset;
    margin-left: 8px;
  }
  .ltr-17jqah9 {
    text-align: left;
  }
  .styled-checkbox:checked + label:after {
    right: 15px;
    left: unset;
  }
}
