.category-section {
  .title-section {
    border-bottom: 1px solid;
    border-color: $border_1;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 8px;
    padding-top: 8px;
    top: 48px;
    z-index: 1000;
    background-color: $bg_color1;
    &:hover {
      opacity: 0.7;
    }
    .category-title {
      font-size: $font-size-16;
      font-weight: $font-weight-600;
      line-height: $line-height-114;
      padding-right: 16px;
      color: $black;
      overflow-wrap: break-word;
      word-break: break-word;
    }
    .cat-arrow {
      line-height: $line-height-1;
      font-size: $font-size-30;
      font-weight: $font-weight-bold;
    }
  }
  .ltr-1xe9geg {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: -0.2px;
    color: rgb(0, 0, 0);
    overflow-wrap: break-word;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .product-desc {
    font-size: $font-size-13;
    font-weight: $font-weight-400;
    line-height: 1.23;
    color: rgb(97, 97, 97);
    overflow-wrap: break-word;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .img {
    height: 92px;
    width: 92px;
    margin-left: 16px;
    object-fit: cover;
    border-radius: 4px;
    flex: 0 0 auto;
  }

  .count-cart {
    padding-right: 8px;
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: -0.2px;
    font-weight: 600;
    padding-right: 4px;
    flex-shrink: 0;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  .product-btn {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    font-size: $font-size-13;
    letter-spacing: -0.1px;
    line-height: 1.23;
    min-height: 32px;
    font-weight: $font-weight-600;
    border-color: rgb(219, 219, 219);
    &:hover {
      outline: none;
      border-color: rgb(74, 74, 74);
    }
  }
  .list-group-item-action {
    &:active {
      background-color: #fff !important;
    }
    &:hover,
    &:focus {
      z-index: 1;
      text-decoration: none;
      background-color: #fff !important;
      opacity: 0.7;
    }
  }
  .list-group-item {
    border-radius: 0 !important;
  }
  .list-group-item.active {
    z-index: 2;
    color: unset;
    background-color: #fff !important;
    border-left: 2px solid red !important;
    border-color: rgba(0, 0, 0, 0.125);
    border-radius: 0 !important;
  }
  .extra-info {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.23;
    letter-spacing: -0.1px;
    margin-top: 8px;
    color: rgb(162, 104, 9);
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

.rtl {
  .category-section {
    .list-group-item.active {
      border-right: 2px solid red !important;
      border-left: unset !important;
    }
    .count-cart {
      padding-left: 8px;
      padding-right: unset;
    }
    .img {
      margin-right: 16px;
      margin-left: unset;
    }
  }
}

.ltr-uf4fm0 {
  height: 100%;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 5000;
  background-color: rgba(4, 4, 15, 0.4);
}

.one-tap-ordering {
  text-align: left;
}

.rtl {
  .one-tap-ordering {
    text-align: right;
  }

  .navbar-toggler,
  .link-header,
  .checkout-arrow {
    transform: rotate(180deg);
  }

  .ltr-uf4fm0,
  .field-placeholder {
    right: 0px;
    left: unset;
  }
  .PhoneInputCountrySelectArrow {
    margin-right: 5px;
  }
  input:not(:placeholder-shown) ~ label {
    right: 4px;
    left: unset;
  }
  .ltr-14d91vi {
    left: 0px;
    right: unset;
  }
  .count-cart {
    float: right;
  }
}

.rtl-5w76og {
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
}
.rtl-wtvwk0 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  flex-shrink: 0;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}

.extra-status {
  display: none;
}

.ltr-176x4ze {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 8px;
  z-index: 1000;
  background-color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(82, 82, 82, 0.1);
  border-color: rgba(82, 82, 82, 0.1);
  overflow: hidden;
}

.ltr-de47eb {
  margin-top: 0px;
  max-height: calc(100vh - 32px);
  flex-direction: column;
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 0px 0px 16px;
  overflow: auto;
  list-style: none;
}

.ltr-1lj26yz {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  letter-spacing: -0.4px;
  padding-right: 16px;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ltr-1snk7vm {
  font-size: 32px;
  height: 32px;
  color: currentColor;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
  background-color: #0000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-stretch: normal;
  font-style: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 0;
  touch-action: manipulation;
  line-height: 1;
}

.active_cat::before {
  content: '';
  top: 0px;
  bottom: 0px;
  position: absolute;
  height: 100%;
  width: 4px;
}

.ltr-1mg3f9m {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  border-color: rgb(245, 245, 245);
}

.ltr-1gvv4e3 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}

.ltr-1rn4h86 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: rgb(97, 97, 97);
  overflow-wrap: break-word;
  word-break: break-word;
}

.ltr-1bvpidu {
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  touch-action: manipulation;
  padding: 0px;
  transition: color 0.25s ease-in 0s, opacity 0.25s ease-in 0s;
}

@media screen and (min-width: 960px) {
  .ltr-uf4fm0 {
    width: 33.3%;
  }
}
