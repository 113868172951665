.ltr-1pl04lf {
  margin-top: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
}
.ltr-1j8qet7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.ltr-5a3nod {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  border-right: 1px solid;
  border-color: #f5f5f5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.ltr-qvdoh2 {
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  -webkit-letter-spacing: -0.2px;
  -moz-letter-spacing: -0.2px;
  -ms-letter-spacing: -0.2px;
  letter-spacing: -0.2px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  border: none;
  display: inline;
  background: #0000;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  touch-action: manipulation;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 0;
}
.ltr-1vsrr9p {
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.ltr-1fqqef4 {
  position: relative;
}
.ltr-hhxm44 {
  margin-top: 16px;
  position: relative;
  width: inherit;
}
.ltr-xauasu {
  background: none;
  resize: none;
  padding-top: 4px;
  padding-bottom: 8px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid;
  border-color: #f5f5f5;
  color: #000;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  -webkit-letter-spacing: -0.2px;
  -moz-letter-spacing: -0.2px;
  -ms-letter-spacing: -0.2px;
  letter-spacing: -0.2px;
  border: none;
  border-radius: 8px;
  background: #f5f5f5;
  padding: 8px;
  -webkit-appearance: none;
}

.rwt__tabs[data-rwt-vertical='true'] {
  display: flex;
}

.rwt__tablist:not([aria-orientation='vertical']) {
  position: fixed;
  top: 48px;
  z-index: 1000;
  width: 33.33%;
  background-color: #fff;
}

.rwt__tablist[aria-orientation='vertical'] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 1rem;
}

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem 2rem;
  color: rgb(153, 153, 153);
  font-size: 15px;
  font-weight: 500;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.rwt__tab[aria-selected='false']:hover,
.rwt__tab:focus {
  outline: 0;
}

.rwt__tab[aria-selected='true'] {
  position: relative;
  color: #000;
}

.rwt__tab[aria-selected='true']:after {
  content: '';
  position: absolute;
}

.rwt__tablist:not([aria-orientation='vertical'])
  .rwt__tab[aria-selected='true']:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid rgb(0, 0, 0);
}

.rwt__tablist[aria-orientation='vertical']
  .rwt__tab[aria-selected='true']:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 3px solid #00d8ff;
}

.rwt__tabpanel {
  padding-top: 103px;
}

.ltr-1fqqef4 {
  position: relative;
}
.ltr-1tbs9m9 {
  margin-top: 8px;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  width: inherit;
}
.ltr-11xvhlv {
  margin-top: 8px;
  border-bottom: 1px solid rgb(245, 245, 245);
  border-color: rgb(245, 245, 245);
}

.accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  overflow: hidden;
}

.accordion-item {
  $self: &;

  &--opened {
    #{ $self }__icon {
      transform: rotate(180deg);
    }

    #{ $self }__inner {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }

    #{ $self }__content {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: transform;
    }
  }

  &__line {
    display: block;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 2;
    position: relative;
  }

  &__title {
    font-size: 1.6rem;
    margin: 0;
    font-weight: 700;
    color: $cBlack;
  }

  &__icon {
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
  }

  &__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    padding: 0 1.2rem 1.2rem;
  }

  &__paragraph {
    margin: 0;
    font-size: 1rem;
    color: $cGray;
    font-weight: 300;
    line-height: 1.3;
  }
}

.ltr-d0y9pp {
  background-color: rgba(255, 255, 255, 0.7);
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 16px;
  padding-right: 16px;
}

.ltr-13oyw06:focus {
  background-color: rgb(245, 245, 245);
  outline: none;
}

.ltr-1qegtm7 {
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  font-weight: 600;
  margin-right: auto;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}

.rtl {
  .ltr-1qegtm7 {
    margin-left: auto;
    margin-right: unset;
  }
}

.ltr-13oyw06 {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(82, 82, 82, 0.1);
  border-color: rgba(82, 82, 82, 0.1);

  &:hover {
    color: rgb(97, 97, 97);
  }
  &:focus {
    background-color: rgb(245, 245, 245);
    outline: none;
  }
}

.ltr-tcfqt2 {
  margin-top: 0px;
  flex-direction: column;
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 0px;
  list-style: none;
}

.ltr-1bvpidu {
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  touch-action: manipulation;
  padding: 0px;
  transition: color 0.25s ease-in 0s, opacity 0.25s ease-in 0s;
}

.ltr-17nw5cg {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 16px;
  border-bottom: 1px solid rgb(245, 245, 245);
}

.active-area {
  &:before {
    content: '';
    top: 0px;
    bottom: 0px;
    position: absolute;
    height: 100%;
    width: 4px;
  }
}

.ltr-1ipvnc5 {
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  justify-self: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  text-decoration: none;
}

.ltr-xbp7n3 {
  padding-bottom: 16px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  border-bottom: 1px solid rgb(245, 245, 245);
  border-color: rgb(245, 245, 245);
}
.ltr-qeqdm6 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
}

.ltr-ttj5pb {
  line-height: 0;
  margin-left: 8px;
  transform: scale3d(1, 1, 1);
}

.rtl {
  .ltr-ttj5pb {
    margin-left: unset;
    margin-right: 8px;
    svg {
      transform: rotate(180deg);
    }
  }
}
