$body-fontSize: 16px;

$font-weight-600: 600;
$font-weight-bold: bold;
$font-weight-400: 400;

$font-size-16: 16px;
$font-size-17: 17px;
$font-size-9: 9px;
$font-size-30: 30px;
$font-size-13: 13px;
$font-size-15: 15px;

$line-height-114: 1.41;
$line-height-2: 2;
$line-height-1: 1;

$field-container-padding: 5px;
$label-padding: $field-container-padding !default;
