.ltr-1usrurx {
  position: fixed;
  z-index: 3000;
  bottom: 0;
  padding: 8px;
  min-height: 64px;
  background-color: #fff;
  width: 33.33%;
}

.ltr-yheipv {
  width: 100%;
  border-radius: 4px;
  color: #fff;
  border: none;
  font-size: 15px;
  -webkit-letter-spacing: -0.2px;
  -moz-letter-spacing: -0.2px;
  -ms-letter-spacing: -0.2px;
  letter-spacing: -0.2px;
  line-height: 1.27;
  min-height: 48px;
  padding: 14px 16px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  -webkit-text-decoration: none;
  text-decoration: none;
  touch-action: manipulation;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ltr-yheipv:hover:enabled,
.ltr-yheipv:focus {
  outline: none;
  background-color: #bf0414;
}
.ltr-yheipv .content:only-child {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ltr-i6bgv1 {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.23;
  letter-spacing: -0.1px;
  display: block;
  min-width: 32px;
  background-color: rgba(0, 0, 0, 0.25);
  margin: -8px;
  padding: 8px;
  border-radius: 4px;
}
