.ltr-1pvnlgv {
  -webkit-box-align: center;
  align-items: center;
  flex-shrink: 0;
  position: sticky;
  top: 0px;
  z-index: 3000;
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 16px;
}
.ltr-yxrjdm {
  flex: 9 1 0%;
}
.ltr-1fqqef4 {
  position: relative;
}
.ltr-1qg0orz {
  margin-top: 0px;
  position: relative;
  width: inherit;
}
.ltr-xauasu {
  resize: none;
  display: block;
  width: 100%;
  color: rgb(0, 0, 0);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  appearance: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 8px;
  background: rgb(245, 245, 245);
  padding: 8px;
}
.ltr-1qg0orz > * {
  transition: all 0.2s ease 0s;
}
.ltr-r9p7n8 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  overflow-wrap: break-word;
  word-break: break-word;
  display: inline;
  cursor: pointer;
  appearance: none;
  touch-action: manipulation;
  margin-left: 16px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  background: transparent;
  text-decoration: none;
  transition: all 0.2s ease-in 0s;
  padding: 0px;
}
.ltr-2qa8x9 {
  border-bottom: 1px solid rgb(245, 245, 245);
  border-color: rgb(245, 245, 245);
}
.ltr-1m5wpo6 {
  flex-direction: column;
  margin-top: 0px;
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 0px 0px 40px;
}
.ltr-11x4bkd {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.27;
  letter-spacing: 0.3px;
  margin-left: 16px;
  margin-top: 16px;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}

.rtl {
  .ltr-11x4bkd,
  .ltr-1xbfmzv,
  .ltr-r9p7n8 {
    margin-right: 16px;
    margin-left: unset;
  }
}
.ltr-1xbfmzv {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  margin-left: 16px;
  margin-top: 8px;
  color: rgb(97, 97, 97);
  overflow-wrap: break-word;
  word-break: break-word;
}

.ltr-aoug8f {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  margin-left: 16px;
  margin-top: 16px;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}
