$body_bg_color: #fff;
$border_1: #5252521a;
$bg_color1: #ffffffb3;
$main-bg: #f5f5f9;
$gray_1: #616161;
$black: #000;
$charade: #282a37;
$bluebell: #787981;
$cAccent: #3978ef;
$cBlack: #121212;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);
