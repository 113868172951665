@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?xa6esi');
  src:  url('fonts/icomoon.eot?xa6esi#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?xa6esi') format('truetype'),
    url('fonts/icomoon.woff?xa6esi') format('woff'),
    url('fonts/icomoon.svg?xa6esi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-minus:before {
  content: "\e908";
}
.icon-plus:before {
  content: "\e909";
}
.icon-minus1:before {
  content: "\e90b";
}
.icon-plus1:before {
  content: "\e90a";
}
.icon-keyboard_arrow_right:before {
  content: "\e900";
}
.icon-keyboard_arrow_down:before {
  content: "\e901";
}
.icon-appartment:before {
  content: "\e903";
}
.icon-briefcase:before {
  content: "\e904";
}
.icon-clock:before {
  content: "\e905";
}
.icon-home:before {
  content: "\e906";
}
.icon-phone-call-outline:before {
  content: "\e907";
}
.icon-arrow_right:before {
  content: "\e902";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
