.menu-items {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  -webkit-letter-spacing: -0.2px;
  -moz-letter-spacing: -0.2px;
  -ms-letter-spacing: -0.2px;
  letter-spacing: -0.2px;
  font-weight: 600;
  padding-left: 16px !important;
  padding-right: 16px !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  border: none;
  display: inline;
  background: #0000;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  touch-action: manipulation;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 0;
  color: #fff;
  opacity: 0.7;
  &:hover {
    color: #dbdbdb !important;
  }
}

.active-menu-item {
  opacity: 1;
}
.navbar-nav {
  margin: 0 auto;
}

.one-tap-ordering {
  display: none;
}

.order-item {
  border-bottom: 0 !important;
}

@media (max-width: 991.98px) {
  .navbar-nav {
    flex-direction: column;
    margin-top: 8px;
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0px;
    list-style: none;
  }
  .nav-item {
    margin-left: 56px;
    margin-right: 40px;
    overflow-wrap: break-word;
    word-break: break-word;
    border-top-width: initial;
    border-right-width: initial;
    border-left-width: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    display: flex;
    color: rgb(0, 0, 0);
    font-size: 17px;
    line-height: 1.41;
    letter-spacing: -0.4px;
    padding-left: 0px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
    touch-action: manipulation;
    border-style: none none solid;
    border-image: initial;
    background: transparent;
    text-decoration: none;
    border-bottom: 1px solid rgb(245, 245, 245);
    transition: color 0.25s ease-in 0s, opacity 0.25s ease-in 0s;
    .menu-items {
      font-size: 17px;
    }
  }
  .one-tap-ordering {
    flex-direction: column;
    margin-left: 56px;
    margin-top: 56px;
    display: flex;
    .ltr-125d2vb {
      text-align: left;
      font-size: 17px;
      font-weight: 600;
      line-height: 1.41;
      letter-spacing: -0.4px;
      margin-bottom: 16px;
      color: rgb(0, 0, 0);
      overflow-wrap: break-word;
      word-break: break-word;
    }
    .ltr-1gvv4e3 {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.33;
      letter-spacing: -0.2px;
      color: rgb(0, 0, 0);
      overflow-wrap: break-word;
      word-break: break-word;
    }
  }
  .rtl {
    .one-tap-ordering {
      flex-direction: column;
      margin-left: unset;
      margin-right: 56px;
      margin-top: 56px;
    }
    .ltr-125d2vb {
      text-align: right;
    }
    .nav-item {
      margin-right: 56px;
      margin-left: 40px;
    }
  }
}
