.skeleton {
  display: inline-block;
  margin: 0 5px 5px;
  background: linear-gradient(-90deg, #e4e0e0 0%, #f8f8f8 50%, #e4e0e0 100%);
  background-size: 400% 400%;
  animation: left-to-right 1.2s ease-in-out infinite;
  border-radius: 4px;
  color: transparent;
  position: relative;
  z-index: 2;
  border-color: transparent !important;
}
.skeleton:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(-90deg, #e4e0e0 0%, #f8f8f8 50%, #e4e0e0 100%);
  background-size: 400% 400%;
  animation: left-to-right 1.2s ease-in-out infinite;
  z-index: 2;
  content: '';
}
@keyframes left-to-right {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}
