$btn-size: 60px;
$btn-font-color: red;
$btn-font-size: 1rem;
$btn-icon-size: 2rem;
$btn-trasition-time: 0.2s;

.plus-minus-container {
  button {
    font-size: 22px !important;
    font-weight: 400;
    width: 20px;
    height: 20px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
  }
  button[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
  p {
    line-height: 1;
    font-size: 22px;
    font-weight: 400;
  }
}

.btn--circle {
  position: relative;
  font-family: sans-serif;
  text-decoration: none;
  border-radius: 1000px;
}

.btn-stage {
  @include center-absolute;
  font-size: 15px;
}

.minus {
  top: 40% !important;
}
