.items-container {
  border-bottom: 1px solid #f5f5f5;
}

.ltr-14siyw {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  letter-spacing: -0.4px;
  padding-right: 8px;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}

.ltr-1gxuiwm {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.2px;
  padding-right: 8px;
  overflow-wrap: break-word;
  word-break: break-word;
  display: flex;
}

.ltr-1gxuiwm-checkout {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  color: #000;
  letter-spacing: -0.2px;
  padding-right: 8px;
  overflow-wrap: break-word;
  word-break: break-word;
  display: flex;
  text-transform: uppercase;
}

.ltr-1ibwh4p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  margin-bottom: 4px;
  color: #000;
  overflow-wrap: break-word;
  word-break: break-word;
}

.rtl {
  .ltr-1gxuiwm {
    padding-left: 8px;
    padding-right: unset;
  }
}

.ltr-8942aw {
  font-size: 15px;
  font-weight: 500 !important;
  line-height: 1.33;
  -webkit-letter-spacing: -0.2px;
  -moz-letter-spacing: -0.2px;
  -ms-letter-spacing: -0.2px;
  letter-spacing: -0.2px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: #000;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.ltr-1o15x0g {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.23;
  -webkit-letter-spacing: -0.1px;
  -moz-letter-spacing: -0.1px;
  -ms-letter-spacing: -0.1px;
  letter-spacing: -0.1px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: #616161;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.cart-btn {
  color: rgb(0, 0, 0);
  font-size: 13px;
  letter-spacing: -0.1px;
  line-height: 1.23;
  min-height: 32px;
  font-weight: $font-weight-600;
  border: 1px solid rgb(219, 219, 219) !important;
  border-radius: 4px;
  height: 37px;
  &:hover {
    outline: none;
    border-color: rgb(74, 74, 74) !important;
  }
}

.apply-code-btn {
  min-height: 32px;
  font-weight: 600;
  border-color: #dbdbdb !important;
  height: 37px;
}

.empty-bag {
  font-size: 22px !important;
  font-weight: 400;
  line-height: 1.27;
  letter-spacing: 0.3px;
  padding-bottom: 8px;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}
.empty-bag-sub {
  font-size: 15px !important;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  padding-bottom: 16px;
  color: rgb(97, 97, 97);
  overflow-wrap: break-word;
  word-break: break-word;
}

.ltr-8942aw {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: -0.2px;
  flex-shrink: 0;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
}

.remove {
  color: rgb(0, 0, 0);
  font-size: 13px;
  letter-spacing: -0.1px;
  line-height: 1.23;
}

.order-section {
  margin-top: 40px;
}

.ltr-1fsw27x {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  margin-right: 16px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
  &:hover {
    opacity: 0.7;
  }
}

.ltr-1wi12uh[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
  &:hover {
    border: 1px solid rgb(219, 219, 219) !important;
  }
}

.ltr-s0sui0 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  padding-top: 8px;
  overflow-wrap: break-word;
  word-break: break-word;
}

.unit-label {
  margin-bottom: 8px;
  color: rgb(153, 153, 153);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  pointer-events: none;
  transform: translate3d(0px, 9px, 0px);
  transform-origin: left top;
  transition: all 300ms ease 0s;
}
.ltr-1jdtxyz {
  line-height: 0;
  margin-bottom: 4px;
  font-size: 32px;
  height: 32px;
  color: rgb(74, 74, 74);
}
.ltr-993o66 {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.23;
  letter-spacing: -0.1px;
  color: rgb(97, 97, 97);
  overflow-wrap: break-word;
  word-break: break-word;
}
.input-feedback {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  padding-top: 8px;
  overflow-wrap: break-word;
  word-break: break-word;
}

.ltr-tpgxpi {
  background-color: rgb(255, 255, 255);
  font-size: 15px;
  letter-spacing: -0.2px;
  line-height: 1.27;
  min-height: 48px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  font-stretch: normal;
  font-style: normal;
  touch-action: manipulation;
  user-select: none;
  font-weight: normal;
  border-radius: 4px;
  padding: 14px 16px;
  text-decoration: none;
  transition: all 0.2s ease-in 0s;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border-top: 1px solid #ebebf4;
}
