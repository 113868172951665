.ltr-b6njci {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}
.ltr-xv8pht {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  background-color: rgb(245, 245, 245);
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  text-transform: uppercase;
  word-break: break-word;
  border-radius: 4px;
  border-width: 1px;
  border-image: initial;
  border-color: rgb(158, 158, 158);
  border-style: dotted;
  padding: 8px;
}
.ltr-dl8qll {
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  font-weight: 600;
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.84);
  overflow-wrap: break-word;
  word-break: break-word;
}
.ltr-vsl5ie {
  padding-bottom: 8px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.ltr-14siyw {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  -webkit-letter-spacing: -0.4px;
  -moz-letter-spacing: -0.4px;
  -ms-letter-spacing: -0.4px;
  letter-spacing: -0.4px;
  padding-right: 8px;
  color: #000;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.ltr-imi5h7 {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.41;
  letter-spacing: -0.4px;
  color: rgb(0, 0, 0);
  overflow-wrap: break-word;
  word-break: break-word;
  margin-right: 5px;
}
.ltr-19sapue {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  margin-top: 16px;
  margin-bottom: 8px;
  color: rgb(97, 97, 97);
  overflow-wrap: break-word;
  word-break: break-word;
}
.status-btn {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  font-size: 13px;
  letter-spacing: -0.1px;
  line-height: 1.23;
  min-height: 32px;
  cursor: pointer;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  touch-action: manipulation;
  user-select: none;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(219, 219, 219);
  border-image: initial;
  padding: 8px 16px;
}

.ltr-tse85g {
  flex-direction: column;
  margin-top: 8px;
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 16px;
}
